import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {createCardAction, getOneCardAction} from "../../_api/CardApi";
import {Avatar, Col, Drawer, InputNumber, Popconfirm, Select, Table} from "antd";
import CommentsComponent from "../../_components/CommentsComponent";
import {getAllStudyLogsAction} from "../../_api/StudyLogApi";
import DataTableComponent from "../../_components/DataTableComponent";
import Column from "antd/es/table/Column";
import {getCommonColumnRequestStruct} from "../../_api/CommonApi";
import DateField from "../../_components/Fields/DateField";
import TextArea from "antd/lib/input/TextArea";
import AvatarField from "../../_components/Fields/AvatarField";
import {useSelector} from "react-redux";
import {mapSelectData} from "../../_classes/Helper";
import Notification from "../../_classes/Notification";
import {approveTransactionAction, createTransactionAction, getAllTransactionsAction} from "../../_api/TransactionApi";
import DatetimeField from "../../_components/Fields/DatetimeField";
import {CheckCircleFilled, FolderOpenFilled} from "@ant-design/icons";

const ShowCardScreen = () => {
    const {id} = useParams()
    const [card, setCard] = useState({})
    useEffect(() => {
        getOneCardAction(id, {}).then(r => {
            if (r.success) {
                setCard(r?.data)
            }
        })
    }, [id]);

    return (
        <AuthenticatedLayout layout={false}>
            {card?.id &&
                <React.Fragment>
                    <div className={"row"}>
                        <Col xl={18} sm={12} className={"p-2"}>
                            <div className={"p-2 bg-white rounded h-100"}>
                                <div className={"row"}>
                                    <CardDetail card={card} id={id} callback={() => {
                                        getOneCardAction(id, {}).then(r => {
                                            if (r.success) {
                                                setCard(r?.data)
                                            }
                                        })
                                    }}/>
                                </div>
                                <div>
                                    <CardTransaction card={card} callback={() => {
                                        getOneCardAction(id, {}).then(r => {
                                            if (r.success) {
                                                setCard(r?.data)
                                            }
                                        })
                                    }}/>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} sm={12} className={"p-2 h-100"}>
                            <div className={"p-2 bg-white rounded"}>
                                <CommentsComponent changeData={() => {
                                }} objectType={'card'} objectId={card?.id} height={"40rem"}/>
                            </div>
                        </Col>
                    </div>
                    <div className={"p-2 bg-white rounded mt-2"}>
                        <div className={"my-2 text-purple-bright fw-bold"}>Buổi học có thẻ học</div>
                        <div>
                            <StudyLogListByCard id={id}/>
                        </div>
                    </div>
                </React.Fragment>
            }
        </AuthenticatedLayout>
    )
}
export default ShowCardScreen

const CardDetail = ({card, id, callback}) => {
    const [transactionDrawer, openTransactionDrawer] = useState(false)
    return (
        <div className={"px-3 mt-2"}>
            <div className={"text-purple-bright h5"}>Thẻ học : {card?.uuid}</div>
            {card?.student?.name &&
                <div className={"d-flex align-items-center my-3"}>
                    <div className={"avatar"}>
                        <img src={card?.student?.avatar} style={{width: '2.5rem'}}/>
                    </div>
                    <div className={"ms-2"}>
                        <div className={"fw-bold"}>{card?.student?.name}</div>
                        <div className={"text-muted text-xs"}>{card?.student?.uuid}</div>
                    </div>
                </div>
            }
            {card?.classroom?.name &&
                <div className={"d-flex align-items-center my-3"}>
                    <div className={"avatar"}>
                        <img src={card?.classroom?.avatar} style={{width: '2.5rem'}}/>
                    </div>
                    <div className={"ms-2"}>
                        <div className={"fw-bold"}>{card?.classroom?.name}</div>
                        <div className={"text-muted text-xs"}>{card?.classroom?.uuid}</div>
                    </div>
                </div>
            }
            <div className={"row"}>
                <CardBoxInfo value={card?.total_days}
                             title={"Tổng số buổi đăng ký"}
                             description={`Số buổi đăng ký gốc (${card?.original_days}) + Số buổi tặng thêm (${card?.bonus_days || 0})`}
                />
                <CardBoxInfo value={card?.total_fee?.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }) + " đ"}
                             title={"Tổng số tiền cần thanh toán"}
                             description={`Học phí gốc (${card?.original_fee?.toLocaleString()} đ) - Ưu đãi (${card?.promotion_fee?.toLocaleString(undefined, {
                                 minimumFractionDigits: 0,
                                 maximumFractionDigits: 0
                             })} đ)`}
                />
                <CardBoxInfo value={card?.daily_fee?.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }) + " đ"}
                             title={"Đơn giá hiện tại"}
                             description={`Tổng số buổi / Tổng số tiền phải thanh toán`}
                />
                {/*Đặc biệt*/}
                <Col xl={12} md={24}>
                    <div
                        className={"d-flex justify-content-between align-items-center p-2 border rounded bg-shadow mb-3"}>
                        <div>
                            <div className={"fw-bold"}>Tổng số tiền đã thanh toán</div>
                            <div className={"text-muted"}>Dựa theo tất cả các giao dịch trên thẻ học</div>
                        </div>
                        <div className={"fw-bold text-purple-bright"}>{card?.paid_fee?.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })} đ
                        </div>
                    </div>
                </Col>
                {/*HIHI*/}
                <Col xl={12} md={24}>
                    <div
                        className={"d-flex justify-content-between align-items-center p-2 border rounded bg-shadow mb-3"}>
                        <div>
                            <div className={"fw-bold"}>Số tiền chưa thanh toán</div>
                            <div onClick={() => {
                                openTransactionDrawer(true)
                            }} className={"text-purple-bright fw-bold cursor-pointer"}>Tạo giao dịch
                            </div>
                            <QuickTransactionCreate
                                open={transactionDrawer}
                                setOpen={openTransactionDrawer}
                                card_id={id}
                                callback={callback}
                            />
                        </div>
                        <div className={"fw-bold text-purple-bright"}>{card?.unpaid_fee?.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })} đ
                        </div>
                    </div>
                </Col>
                <CardBoxInfo value={card?.van_and_attended_days}
                             title={"Số buổi đã trừ"}
                             description={"Van + Số buổi bị trừ theo điểm danh"}
                />
                <CardBoxInfo value={card?.can_use_day_by_paid?.toLocaleString()}
                             title={"Số buổi còn lại có thể sử dụng"}
                             description={"Theo thanh toán hiện tại"}
                />
                <CardBoxInfo value={card?.can_use_fee?.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }) + " đ"}
                             title={"Học phí còn lại có thể sử dụng"}
                             description={`Số buổi đã sử dụng theo thanh toán (${card?.can_use_day?.toLocaleString()})`}
                />
            </div>
        </div>
    )
}

const CardBoxInfo = ({title, description, value}) => {
    return (
        <Col xl={12} md={24}>
            <div className={"d-flex justify-content-between align-items-center p-2 border rounded bg-shadow mb-3"}>
                <div>
                    <div className={"fw-bold"}>{title}</div>
                    <div className={"text-muted"}>{description}</div>
                </div>
                <div className={"fw-bold text-purple-bright"}>{value}</div>
            </div>
        </Col>
    )
}

const StudyLogListByCard = ({id}) => {
    const [studylogs, setStudylogs] = useState([])
    const [columnStruct, setColumnStruct] = useState([])
    const [query, setQuery] = useState({
        filtering: {
            card: id
        }
    })
    useEffect(() => {
        getAllStudyLogsAction(query).then(r => {
            setStudylogs(r?.data)
        })
    }, [query])


    useMemo(() => {
        getCommonColumnRequestStruct('studylog').then((r) => {
            if (r?.success) {
                setColumnStruct(r?.data)
            }
        })
    }, [])

    return (
        <div>
            <DataTableComponent
                setCollection={setStudylogs}
                collection={studylogs}
                setQuery={setQuery}
                query={query}
                columnStructs={columnStruct}
            />
        </div>
    )
}

const QuickTransactionCreate = ({
                                    card_id, open, setOpen, callback = () => {
    }
                                }) => {
    const [data, setData] = useState({
        object_type: "card",
        object_id: card_id,
        transaction_type: "new",
        ref_user: null

    })
    const [errors, setErrors] = useState([])

    const commonState = useSelector((state) => state.common)

    const handleCreateTransaction = () => {
        createTransactionAction(data).then((r) => {
            if (r?.success) {
                Notification.success("Thành công")
                setOpen(false)
                callback()
            } else {
                setErrors(r?.errors)
            }
        })
    }

    return (
        <Drawer
            closable
            destroyOnClose
            title={"Thêm mới giao dịch - Đóng học phí"}
            placement="right"
            loading={false}
            size={'large'}
            onClose={() => {
                setOpen(false)
            }}
            open={open}>
            <div>
                <div className={"row"}>
                    <Col xl={12} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Số tiền đóng</div>
                        <InputNumber
                            value={data?.amount || 0}
                            onChange={(r) => setData({...data, amount: r})}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            placeholder={"Số tiền"} className={"w-100"}
                            suffix={"đ"}
                            size={"large"}
                        />
                    </Col>
                    <Col xl={12} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Ngày đóng học phí</div>
                        <DatetimeField value={data?.transaction_day} setValue={(r) => {
                            setData({...data, transaction_day: r})
                        }}/>
                    </Col>

                    <Col xl={12} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Phương thức thanh toán</div>
                        <Select
                            size={"large"}
                            defaultValue={"cash"}
                            value={data?.payment_method}
                            onChange={(r) => setData({...data, payment_method: r})}
                            options={[
                                {
                                    value: "cash",
                                    label: "Tiền mặt"
                                },
                                {
                                    value: "atm",
                                    label: "Chuyển khoản ngân hàng"
                                },
                                {
                                    value: "credit",
                                    label: "Thẻ trả góp"
                                }
                            ]}
                            className={"w-100"}
                        />
                    </Col>
                    <Col xl={12} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Phương thức thanh toán</div>
                        <Select
                            size={"large"}
                            defaultValue={"new"}
                            value={data?.transaction_type}
                            onChange={(r) => setData({...data, transaction_type: r})}
                            options={[
                                {
                                    value: "new",
                                    label: "Mới (New)"
                                },
                                {
                                    value: "renew",
                                    label: "Gia hạn (Renew)"
                                },
                            ]}
                            className={"w-100"}
                        />
                    </Col>
                    <Col xl={24} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Ghi chú thanh toán</div>
                        <TextArea
                            status={errors['notes'] ? "error" : null}
                            value={data?.notes}
                            onChange={(r) => {
                                setData({...data, notes: r.target.value})
                            }}
                            style={{minHeight: "200px"}}
                            size={"large"}
                            rootClassName={'p-2'}
                            placeholder={""}/>
                    </Col>
                    <Col xl={24} md={24}>
                        <div className={"mb-3"}>
                            <div className={"fw-bold mb-1"}>Ghi nhận doanh số cho</div>
                            <Select
                                value={data?.ref_user}
                                onChange={r => setData({...data, ref_user: r})}
                                size={"large"} options={
                                mapSelectData({
                                    key: "id",
                                    label: "uuid",
                                    prefix: "name",
                                    data: commonState?.users
                                })
                            } className={"w-100"}/>
                        </div>
                    </Col>
                </div>

                <div className={"mb-3"}>
                    <div className={"fw-bold mb-1"}>Bằng chứng thanh toán (Phiếu thu, giao dịch, ...)</div>
                    <AvatarField value={data?.object_image} setValue={(r) => {
                        setData({...data, object_image: r})
                    }}/>
                </div>

                <div className={"create"}>
                    <button onClick={handleCreateTransaction} className={"btn-hologram"}>Đóng học phí</button>
                </div>
            </div>
        </Drawer>
    )
}

const CardTransaction = ({card, callback}) => {
    const [transactions, setTransaction] = useState([])
    useEffect(() => {
        if (card?.id) {
            getAllTransactionsAction({
                filtering: {
                    object_type: "card",
                    object_id: card?.id
                }
            }).then(r => {
                if (r?.success) {
                    setTransaction(r?.data?.data)
                }
            })
        }
    }, [card?.unpaid_fee])

    const handleApprove = ({id}) => {
        approveTransactionAction(id).then(r => {
            if (r?.success) {
                callback()
            }
        })
    }

    return (
        <div className={"p-1"}>
            <div className={"text-purple-bright mb-2 fw-bold"}>Giao dịch thẻ học</div>
            <table className={"w-100"}>
                <thead>
                <tr>
                    <th className={"border p-1 fw-bold text-center"}>Mã giao dịch</th>
                    <th className={"border p-1 fw-bold text-center"}>Ngày giao dịch</th>
                    <th className={"border p-1 fw-bold text-center"}>Số tiền</th>
                    <th style={{minWidth: '15rem'}} className={"border p-1 fw-bold text-center"}>Ghi chú</th>
                    <th className={"border p-1 fw-bold text-center"}>Đinh kèm</th>
                    <th className={"border p-1 fw-bold text-center"}>Trạng thái</th>
                </tr>
                </thead>
                <tbody>
                {transactions?.map((transaction, key) =>
                    <tr>
                        <th className={"border p-1 px-3 text-center"}>#{transaction?.uuid}</th>
                        <th className={"border p-1 px-3 text-center"}>{transaction?.transaction_day}</th>
                        <th className={"border p-1 px-3 text-end fw-bold text-purple-bright text-center"}>{transaction?.amount?.toLocaleString()} đ</th>
                        <th className={"border p-1 px-3"}>
                            <div>
                                Người tạo giao dịch : <span
                                className={"fw-bold text-purple-bright"}>{transaction?.creator?.name} ({transaction?.creator?.uuid})</span>
                            </div>
                            <div>{transaction?.notes}</div>
                        </th>
                        <th className={"border p-1 px-3 text-center"}>
                            {transaction?.object_image &&
                                <FolderOpenFilled onClick={() => {
                                    window.open(transaction?.object_image)
                                }} className={"text-purple-bright"}/>
                            }
                        </th>
                        <th className={"border p-1 px-3 text-center"}>
                            <div className={"d-flex justify-content-center align-items-center"}>
                                <span>{transaction?.status === 1 ? "Đã duyệt" : "Chờ duyệt"}</span>
                                <span className={"ms-2"}>{transaction?.user_abilities?.can_approve === 1 &&
                                    <Popconfirm
                                        onConfirm={() => {
                                            handleApprove({id: transaction?.id})
                                        }}
                                        okText={"Duyệt"}
                                        cancelText={"Xem xét lại"}
                                        title={"Duyệt giao dịch này ?"}>
                                        <CheckCircleFilled className={"text-purple-bright"}/>
                                    </Popconfirm>
                                }</span>
                            </div>
                        </th>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}