import {DeleteOutlined, EditOutlined, EyeOutlined, XOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {deleteEntityByType} from "../../_api/CommonApi";
import {toast} from "react-toastify";

const ActionColumnComponent = ({user_ability, deletedEvent}) => {
    const nav = useNavigate()

    const handleForwardEdit = () => {
        const url = `/${user_ability?.entity}/edit/${user_ability?.id}`
        nav(url)
    }

    const handleForwardShow = () => {
        const url = `/${user_ability?.entity}/show/${user_ability?.id}`
        nav(url)
    }

    const handleDeleted = () => {
        deleteEntityByType(user_ability?.entities, user_ability?.id).then(r => {
            if (r?.success) {
                toast.success(r?.data?.message)
                deletedEvent(user_ability?.id)
            }
        })
    }

    return (
        <div className={"d-flex align-items-center justify-content-center"}>
            {user_ability?.can_edit === 1 &&
                <EditOutlined onClick={() => handleForwardEdit()} className={"cursor-pointer text-primary me-2"}/>
            }
            {user_ability?.can_access === 1 &&
                <EyeOutlined onClick={() => handleForwardShow()} className={"cursor-pointer text-primary me-2"}/>
            }
            {user_ability?.can_delete === 1 &&
                <DeleteOutlined onClick={() => handleDeleted()} className={"cursor-pointer text-primary"}/>
            }
        </div>
    )
}

export default ActionColumnComponent