import axios from 'axios';
import {toast} from "react-toastify";

class Api {
    constructor() {
        let baseURL = window.location.origin
        if (window.location.origin === 'https://test.com.vn:3000') {
            baseURL = 'https://bsmlight-v2.com.vn'
        }
        this.client = axios.create({
            baseURL: baseURL
        });
    }

    // Phương thức chung để gọi API
    async request(method, endpoint, data = {}, headers = {}) {
        try {
            const response = await this.client.request({
                url: endpoint,
                method,
                data,
                params: method === 'get' ? data : undefined,
                headers
            });
            return {success: true, data: response.data};
        } catch (error) {
            return this.handleError(error);
        }
    }

    // Phương thức để xử lý lỗi
    handleError(error) {
        const errorMessage = error.response?.data?.message || 'An error occurred';
        const errorBags = error.response?.data?.errors || [];
        const code = error?.response?.status;
        switch (error?.response?.status) {
            case 400:
                toast.warning(errorMessage, {
                    position: "top-center"
                })
                break;
            case 404:
                toast.warning(errorMessage, {
                    position: "top-center"
                })
                break;
            case 402:
            case 403:
                toast.error(errorMessage, {
                    position: "top-center"
                })
                break;
            case 422:
                toast.error(errorMessage, {
                    position: "top-center"
                })
            // eslint-disable-next-line no-fallthrough
            case 401:
                // localStorage.removeItem('jwt_token')
                break;
            default:
                break;
        }
        return {success: false, message: errorMessage, errors: errorBags, code: code};
    }

    // Các phương thức API cụ thể
    async post(endpoint, data, headers = {}) {
        return this.request('post', endpoint, data, headers);
    }

    async get(endpoint, params = {}, headers = {}) {
        return this.request('get', endpoint, params, headers);
    }

    async put(endpoint, data, headers = {}) {
        return this.request('put', endpoint, data, headers);
    }

    async delete(endpoint, headers = {}) {
        return this.request('delete', endpoint, {}, headers);
    }
}

export default Api;
