import {Badge, Breadcrumb, Button, Drawer, Dropdown, Layout, Menu, theme} from "antd";
import {Content} from "antd/es/layout/layout";
import React, {useEffect, useMemo, useState} from "react";
import appLogo from '../_assets/images/app_logo.png';
import {_menu} from "../_statics/_menu";
import {BellFilled, MenuFoldOutlined, MessageFilled} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {getAllSharedUserAction, getCurrentUserAction, getUserPermissionsAction} from "../_api/CommonApi";
import {useDispatch, useSelector} from "react-redux";
import {commonAction} from "../_provider/_slices/CommonSlice";
import Notification from "../_classes/Notification";
import {comingSoon, handleLogout} from "../_classes/Helper";
import MenuComponent from "../_components/MenuComponent";
import WebSocketClient from "../_classes/WebsocketClient";
import NotificationDrawer from "../_components/Drawers/NotificationDrawer";
import {useSocket} from "../_provider/SocketContext";
import {toast} from "react-toastify";

const AuthenticatedLayout = ({children, layout = true}) => {
    const nav = useNavigate()
    const dispatch = useDispatch()
    const commonState = useSelector((state) => state.common)
    const [openNotification,setOpenNotification] = useState(false)
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();

    const [collapsed, setCollapsed] = useState(localStorage.getItem('menu_collapsed') === '1');

    const toggleCollapsed = () => {
        localStorage.setItem('menu_collapsed', collapsed ? '0' : '1')
        setCollapsed(!collapsed);
    };

    const onClick = (e) => {
        console.log('click ', e);
        nav(`/${e.key}`)
    };

    useEffect(() => {
        getCurrentUserAction().then((response) => {
            if (response?.data?.branch === 'UNSELECTED') {
                nav("/branches")
            }

            dispatch(commonAction({currentUser: response?.data}))
        }).catch((error) => {

        })
    }, []);

    useEffect(() => {
        getAllSharedUserAction().then((response) => {
            dispatch(commonAction({users: response?.data}))
        })
    }, [])

    useEffect(() => {
        getUserPermissionsAction().then((response) => {
            dispatch(commonAction({permissions: response?.data}))
        })
    }, [])

    const socket = useSocket();

    useEffect(() => {
        if (!socket) return;
        if (!commonState.currentUser) return;

        const handleMessage = (message) => {
            console.log(message)
        };

        socket.emit('join', commonState.currentUser?.uuid);

        socket.on('chat message', handleMessage);

        return () => {
            socket.off('chat message', handleMessage);
        };
    }, [socket, commonState.currentUser]);

    return (
        <Layout>
            <Content
                style={{
                    padding: '0',
                }}
            >
                <Layout
                    className={"d-flex flex-row"}
                    style={{
                        padding: '0 0',
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <div
                        className={"position-relative"}
                        style={{
                            background: colorBgContainer,
                            // height: "100%",

                        }}
                    >
                        <div className={"position-sticky top-0"}>
                            <div
                                className={"d-flex p-1 px-3 align-items-center " + (collapsed ? "justify-content-center" : "justify-content-between")}>
                                <img src={appLogo || ''} className={"cursor-pointer"} onClick={toggleCollapsed}
                                     style={{height: '2rem'}}
                                     alt={"bsm_logo"}/>
                                <div
                                    className={'cursor-pointer'}
                                    onClick={toggleCollapsed}
                                >
                                    {collapsed ? null : <MenuFoldOutlined/>}
                                </div>
                            </div>

                            <MenuComponent onClick={onClick} collapsed={collapsed}/>
                        </div>
                    </div>
                    <Content
                        style={{
                            padding: '0',
                            minHeight: 280,
                            width: "75%"
                        }}
                        className={"bg-base position-relative"}
                    >
                        <div className={"p-2 py-3 bg-white justify-content-between d-flex align-items-center"}>
                            <div className={"fw-bold text-purple-bright"}>Xin
                                chào, {commonState?.currentUser?.name}</div>
                            <div className={"d-flex justify-content-center align-items-center"}>
                                <div className={"text-purple-bright bg-white rounded p-2 fw-bold"}>Chi nhánh
                                    : {commonState?.currentUser?.branch}</div>
                                {parseInt(commonState?.currentUser?.role) === 1 &&
                                    <span onClick={() => {
                                        nav('/branches')
                                    }} className={"cursor-pointer fw-bold"}>| Chuyển chi nhánh</span>
                                }
                            </div>
                            <div className={"d-flex align-items-center"}>
                                <div onClick={() => {setOpenNotification(true)
                                }} className={"border border-3 rounded-circle bg-base text-center mx-2"}>
                                    <Badge count={5}>
                                        <BellFilled className={"h5 mb-0 cursor-pointer text-gray"}
                                                    style={{width: "2rem", height: "2rem", justifyContent: "center"}}/>
                                    </Badge>

                                </div>
                                <NotificationTab open={openNotification} setOpen={setOpenNotification}/>
                                <div onClick={comingSoon}
                                     className={"border border-3 rounded-circle bg-base text-center mx-2"}>
                                    <Badge count={100}>
                                        <MessageFilled className={"h5 mb-0 cursor-pointer text-gray"}
                                                       style={{
                                                           width: "2rem",
                                                           height: "2rem",
                                                           justifyContent: "center"
                                                       }}/>
                                    </Badge>
                                </div>

                                <div className={"px-1"}>
                                    <Dropdown
                                        menu={{
                                            items:
                                                [
                                                    {
                                                        key: '1',
                                                        label: (
                                                            <div onClick={() => {
                                                                handleLogout()
                                                            }}>
                                                                Đăng xuất
                                                            </div>
                                                        ),
                                                    },
                                                    {
                                                        key: '2',
                                                        label: (
                                                            <div onClick={() => {
                                                                Notification.success("s")
                                                            }}>
                                                                Sửa thông tin
                                                            </div>
                                                        ),
                                                    }
                                                ]
                                        }}
                                        placement="bottom"
                                    >
                                        <div className={"border border-3 cursor-pointer rounded-circle"}>
                                            <img alt={"avatar"} className={"rounded-circle border-white"}
                                                 src={commonState?.currentUser?.avatar}
                                                 style={{width: "2rem", height: '2rem'}}
                                            />
                                        </div>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>

                        <Breadcrumb
                            style={{
                                margin: '0',
                            }}
                        >
                            {/*<Breadcrumb.Item>Home</Breadcrumb.Item>*/}
                            {/*<Breadcrumb.Item>List</Breadcrumb.Item>*/}
                            {/*<Breadcrumb.Item>App</Breadcrumb.Item>*/}
                        </Breadcrumb>
                        <div className={"wrapper p-3 bg-base p-2"}>
                            {layout ?
                                <div className={"p-3 rounded bg-white"}>
                                    {children}
                                </div>
                                :
                                <div className={"p-3"}>
                                    {children}
                                </div>
                            }
                        </div>
                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}
export default AuthenticatedLayout

const NotificationTab = ({open,setOpen}) => {
    const drawerStyles = {
        mask: {

        },
        content: {

        },
        header: {

        },
        body: {
            margin:0,
            padding:5
        },
        footer: {

        },
    };

    return (
        <Drawer
            styles={drawerStyles}
            closable
            destroyOnClose
            title={"Thông báo app"}
            placement="right"
            size={"default"}
            onClose={() => {
                setOpen(false)
            }}
            open={open}
            width={540}
        >
            <div>
                <NotificationDrawer/>
            </div>
        </Drawer>
    )
}