import DataTableComponent from "../../_components/DataTableComponent";
import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useEffect, useState} from "react";
import FilteringComponent from "../../_components/Columns/FilteringComponent";
import {Button} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {handleCreateUrlParams, handleParseUrlParams} from "../../_classes/Helper";
import {getAllCardsAction} from "../../_api/CardApi";
import {useDispatch} from "react-redux";
import {commonAction} from "../../_provider/_slices/CommonSlice";

const ListCardsScreen = () => {
    const location = useLocation();
    const [staffsCollection, setStaffsCollection] = useState({})
    const nav = useNavigate()
    const [query, setQuery] = useState()
    const dispatch = useDispatch()
    const columnStruct = [
        {
            title: 'Học sinh',
            dataIndex: 'id',
            key: 'id',
            type: "student_card_info",
        },
        {
            title: 'Lớp đang được xếp',
            dataIndex: 'classroom_id',
            key: 'classroom_id',
            type: 'classroom_info'
        },
        {
            title: 'Điểm danh lần cuối',
            dataIndex: 'last_active',
            key: 'last_active',
        },
        {
            title: 'Tổng số buổi đăng ký',
            dataIndex: 'total_days',
            key: 'total_days',
            type: 'number'
        },
        {
            title: 'Tổng số tiền cần thanh toán',
            dataIndex: 'total_fee',
            key: 'total_fee',
            type: 'number'
        },
        {
            title: 'Đơn giá một buổi',
            dataIndex: 'daily_fee',
            key: 'daily_fee',
            type: 'number'
        },
        {
            title: 'Tổng số tiền đã thanh toán',
            dataIndex: 'paid_fee',
            key: 'paid_fee',
            type: 'number'
        },
        {
            title: 'Số buổi được sử dụng theo thanh toán',
            dataIndex: 'can_use_day_by_paid',
            key: 'can_use_day_by_paid',
            type: 'number'
        },
        {
            title: 'Số tiền chưa thanh toán',
            dataIndex: 'unpaid_fee',
            key: 'unpaid_fee',
            type: 'number'
        },
        {
            title: 'Van và số buổi bị trừ khi điểm danh',
            dataIndex: 'van_and_attended_days',
            key: 'van_and_attended_days',
            type: 'number'
        },
        {
            title: 'Ngày chốt điểm danh ở hệ thống cũ',
            dataIndex: 'van_date',
            key: 'van_date',
            type: 'date'
        },
        {
            title: 'Số buổi còn lại có thể sử dụng',
            dataIndex: 'can_use_day',
            key: 'can_use_day',
            type: 'number'
        },
        {
            title: 'Phân loại renew',
            dataIndex: 'renew_type',
            key: 'renew_type',
            type: 'renew',
            width:120
        },
        {
            title: 'Số học phí còn lại có thể sử dụng',
            dataIndex: 'can_use_fee',
            key: 'can_use_fee',
            type: "number"
        },
        {
            title: 'Điểm feedback',
            dataIndex: 'feedback_score',
            key: 'feedback_score',
            type: "number"
        },
        {
            title: 'Ngày tháng năm lấy feedback',
            dataIndex: 'latest_feedback_date',
            key: 'latest_feedback_date',
            type: "date"
        },
        {
            title: 'Trạng thái tiến độ sale',
            dataIndex: 'sale_status',
            key: 'sale_status',
        },
        {
            title: 'Ngày tháng năm cập nhật sale',
            dataIndex: 'sale_updated_at',
            key: 'sale_updated_at',
            type: 'date'
        },
        {
            title: 'Hành động',
            dataIndex: 'user_abilities',
            key: 'user_abilities',
            type: "user_abilities"
        }
    ];
    const filteringStructs = [
        {
            field: "name",
            type: "text",
            label: "Tên học sinh"
        },
    ]

    useEffect(() => {
        if (query) {
            const urlPath = handleCreateUrlParams(query)
            nav(urlPath)
            dispatch(commonAction({loadingState:true}))
            getAllCardsAction(query).then((response) => {
                if (response?.success) {
                    setStaffsCollection(response?.data)
                    dispatch(commonAction({loadingState:false}))
                } else {
                    dispatch(commonAction({loadingState:false}))
                }
            }).catch(()=>{
                dispatch(commonAction({loadingState:false}))
            })
        }
    }, [query]);

    useEffect(() => {
        const searchParams = location.search.substring(1);
        const parseParams = handleParseUrlParams(searchParams)

        setQuery({
            limit: parseParams?.limit ?? 20,
            order: parseParams?.order,
            direction: parseParams?.direction,
            filtering: parseParams?.filtering,
            offset: parseParams?.offset || 0
        })
    }, [])

    return (
        <AuthenticatedLayout>
            <div className={"h4 text-purple-bright"}>Danh sách thẻ học</div>
            <div className={"d-flex justify-content-between my-4"}>
                <FilteringComponent
                    filteringStructs={filteringStructs}
                    query={query}
                    setQuery={setQuery}
                />
                <Button onClick={() => nav('/card/create')} className={'btn-purple-bright'}>Thêm mới</Button>
            </div>
            <div>
                <DataTableComponent
                    columnStructs={columnStruct}
                    collection={staffsCollection}
                    query={query}
                    setQuery={setQuery}
                    setCollection={setStaffsCollection}
                />
            </div>
        </AuthenticatedLayout>
    )
}

export default ListCardsScreen