import AuthenticatedLayout from "../_layouts/AuthenticatedLayout";
import HomeUserInformation from "../_components/Home/HomeUserInformation";
import {Col, Layout, Row} from "antd";
import HomeImportStepComponent from "../_components/Home/HomeImportStepComponent";
import HomeCreatePost from "../_components/Home/HomeCreatePost";
import HomeUserPosts from "../_components/Home/HomeUserPosts";
import HomeStatisticComponent from "../_components/Home/HomeStatisticComponent";

const HomeScreen = () => {
    return (
        <AuthenticatedLayout layout={false}>
            <div className="App">
                <Row wrap={true}>
                    <Col xxl={5} xl={6} lg={12} className={"p-2"}>
                        <HomeUserInformation/>
                        <div className={"my-3"}></div>
                        <HomeImportStepComponent/>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} className={"p-2"}>
                        <HomeCreatePost/>
                        <div className={"my-3"}></div>
                        <HomeUserPosts/>
                    </Col>
                    <Col xxl={7} xl={6} lg={12} className={"p-2"}>
                        <HomeStatisticComponent/>
                    </Col>
                </Row>
            </div>
        </AuthenticatedLayout>
    )
}
export default HomeScreen