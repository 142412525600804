import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getCommonFormRequestStruct = async (type, id) => {
    return await (new Api()).get(`common/form_struct/${type}?id=${id}`, {}, getAuthHeader());
}

export const getCommonColumnRequestStruct = async (type) => {
    return await (new Api()).get(`common/column_struct/${type}`, {}, getAuthHeader());
}

export const getCurrentUserAction = async (type) => {
    return await (new Api()).get(`current_user`, {}, getAuthHeader());
}

export const getAllSharedUserAction = async () => {
    return await (new Api()).get(`common/users`, {}, getAuthHeader());
}

export const getUserPermissionsAction = async () => {
    return await (new Api()).get(`permissions`, {}, getAuthHeader());
}


export const deleteEntityByType = async (type, id) => {
    return await (new Api()).delete(`${type}/${id}`, getAuthHeader())
}