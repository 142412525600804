import UnAuthenticatedLayout from "../../_layouts/UnAuthenticatedLayout";
import app_logo from "../../_assets/images/logo-ngang.png";
import {Checkbox, Input} from "antd";
import {CodeOutlined, IdcardOutlined, LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import Password from "antd/es/input/Password";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {registerAction} from "../../_api/AuthenticateApi";
import {toast} from "react-toastify";

const RegisterScreen = () => {
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const nav = useNavigate()
    const handleRegister = () => {
        registerAction(data).then((r) => {
            if (r?.success) {
                toast("Đăng ký thành công")
                nav("/login")
            } else {
                setErrors(r?.errors)
            }
        })
    }
    const switchToLogin = () => {
        nav("/login")
    }
    return (
        <UnAuthenticatedLayout>
            <div className={"bg-base d-flex justify-content-center align-items-center"} style={{height: '100vh'}}>
                <div className={"bg-white shadow-lg p-5"} style={{width: 400, borderRadius: 20}}>
                    <div className={"text-purple-bright fw-bold h3 m-0 text-center mb-3"}>
                        <img className={"mb-3"} src={app_logo} style={{width: "180px"}} alt={"app_logo"}/>
                    </div>
                    <div className={"px-1"}>
                        <div className={"mb-2"}>
                            <Input value={data?.name} onChange={(r) => setData({...data, name: r.target.value})}
                                   prefix={<UserOutlined/>} placeholder={"Tên của bạn"}
                                   rootClassName={"p-1 px-2 mb-2"} status={errors?.name ? "error" : false}/>
                            {errors?.name &&
                                <div className={"mb-3 text-xs text-danger"}>{errors?.name}</div>
                            }

                            <Input value={data?.username} onChange={(r) => setData({...data, username: r.target.value})}
                                   prefix={<IdcardOutlined/>} placeholder={"Tên tài khoản"}
                                   rootClassName={"p-1 px-2 mb-2"} status={errors?.username ? "error" : false}/>
                            {errors?.username &&
                                <div className={"mb-3 text-xs text-danger"}>{errors?.username}</div>
                            }

                            <Input value={data?.email} onChange={(r) => setData({...data, email: r.target.value})}
                                   prefix={<MailOutlined/>} placeholder={"Email của bạn"}
                                   rootClassName={"p-1 px-2 mb-2"} status={errors?.email ? "error" : false}/>
                            {errors?.email &&
                                <div className={"mb-3 text-xs text-danger"}>{errors?.email}</div>
                            }
                            <Password value={data?.password}
                                      onChange={(r) => setData({...data, password: r.target.value})}
                                      prefix={<LockOutlined/>} placeholder={"Mật khẩu"}
                                      status={errors?.password ? "error" : false}
                                      rootClassName={"p-1 px-2 mb-2"}/>
                            {errors?.password &&
                                <div className={"mb-3 text-xs text-danger"}>{errors?.password}</div>
                            }

                            <Password value={data?.password_confirmation}
                                      onChange={(r) => setData({...data, password_confirmation: r.target.value})}
                                      prefix={<LockOutlined/>} placeholder={"Nhập lại mật khẩu"}
                                      status={errors?.password_confirmation ? "error" : false}
                                      rootClassName={"p-1 px-2 mb-2"}/>
                            {errors?.password_confirmation &&
                                <div className={"mb-3 text-xs text-danger"}>{errors?.password_confirmation}</div>
                            }
                            <Input value={data?.register_code}
                                   onChange={(r) => setData({...data, register_code: r.target.value})}
                                   prefix={<CodeOutlined/>} placeholder={"Mã khởi tạo Host"}
                                   rootClassName={"p-1 px-2 mb-2"} status={errors?.register_code ? "error" : false}/>
                            {errors?.register_code &&
                                <div className={"mb-3 text-xs text-danger"}>{errors?.register_code}</div>
                            }
                        </div>
                        <div className={"small text-purple-bright mb-3 cursor-pointer"}>
                            <Checkbox checked={data?.accept_policy}
                                      onChange={(r) =>
                                          setData({...data, accept_policy: r.target.checked})
                                      }>
                                Đồng ý với <span className={"text-purple-bright"}>Điều khoản</span> của Bsmlight
                            </Checkbox>
                        </div>
                        <button onClick={handleRegister} className={"btn-purple-bright p-1 px-5 w-100"}>Đăng ký
                        </button>
                        <div className={"mt-3 small text-center"}>
                            <span className={"me-1"}>Bạn đã có tài khoản?</span>
                            <span onClick={switchToLogin} className={"text-purple-bright cursor-pointer"}>
                            Đăng nhập
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </UnAuthenticatedLayout>
    )
}
export default RegisterScreen