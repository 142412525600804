import dayjs from 'dayjs';
import {toast} from "react-toastify";

export const mapSelectData = ({key, label, data, prefix}) => {
    return data?.map((item) => {
        return {
            label: (item[prefix] ? item[prefix] + " - " : '') + item[label],
            value: item[key],
        }
    })
}

export const convertToData = ({data}) => {
    return Object.entries(data)?.map(([key, value]) => {
        return {
            label: value,
            value: key,
        }
    })
}

export const getAuthHeader = () => {
    const jwtToken = localStorage.getItem('jwt_token')

    if (!jwtToken) {
        window.location.href = window.location.origin + "/#/login"
    }

    return {
        "Authorization": "Bearer " + jwtToken
    }
}

export const getUploadUrl = () => {
    let baseURL = window.location.origin
    if (window.location.origin === 'https://test.com.vn:3000') {
        baseURL = 'https://bsmlight-v2.com.vn'
    }

    return baseURL + '/common/upload'
}

export const dayJsToTimestamp = (dayjs) => {
    return dayjs.valueOf() / 1000;
}

export const timestampToDayJs = (timestamp) => {
    return dayjs(timestamp * 1000)
}

export const handleParseUrlParams = (searchParams) => {
    const params = searchParams.split(';');
    console.log(params)
    const queryParams = {
        filtering: {}
    };

    const defaultParams = [
        'fields',
        'order',
        'direction',
        'limit',
        'offset'
    ];

    params.forEach(param => {
        const [key, value] = param.split('=');
        if (defaultParams.includes(key)) {
            queryParams[key] = value;
        } else {
            queryParams['filtering'][key] = value
        }

    });

    return queryParams
}

export const handleCreateUrlParams = (query) => {
    const params = [];

    // Các tham số mặc định
    const defaultParams = [
        'fields',
        'order',
        'direction',
        'limit',
        'offset'
    ];

    // Duyệt qua các tham số mặc định
    defaultParams.forEach(param => {
        if (query[param] !== undefined) {
            params.push(`${param}=${query[param]}`);
        }
    });

    // Duyệt qua các tham số filtering
    if (query['filtering']) {
        Object.keys(query['filtering']).forEach(key => {
            if (query['filtering'][key] !== undefined) {
                params.push(`${key}=${query['filtering'][key]}`);
            }
        });
    }


    return "?" + params.join(';');
};

export const addVariableToUrl = ({data, paramsKey}) => {
    const params = [];

    paramsKey.forEach(param => {
        if (data[param] !== undefined) {
            params.push(`${param}=${data[param]}`);
        }
    });

    return "?" + params.join(';');
};


export const handleParseUrlVariables = (searchParams) => {
    const params = searchParams.split(';');
    console.log()
    console.log(params)
    const queryParams = {};

    params.forEach(param => {
        const [key, value] = param.split('=');
        queryParams[key] = value;
    });

    return queryParams
}

export const handleLogout = () => {
    localStorage.removeItem('jwt_token')
    window.location.reload()
}

export const comingSoon = () => {
    toast.info("Tính năng đang phát triển")
}

export const formatDateYmd = (date) => {
    const parsedDate = new Date(date);
    const day = String(parsedDate.getDate()).padStart(2, '0');
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const year = parsedDate.getFullYear();

    return `${year}-${month}-${day}`;
};

export const formatDateDmY = (date) => {
    const parsedDate = new Date(date);
    const day = String(parsedDate.getDate()).padStart(2, '0');
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const year = parsedDate.getFullYear();

    return `${day}/${month}/${year}`;
};