import {useSelector} from "react-redux";
import {Button, Modal, Select, Upload} from "antd";
import {useState} from "react";
import TextArea from "antd/lib/input/TextArea";
import GalleryUploadFieldComponent from "../GalleryUploadFieldComponent";
import {UploadOutlined} from "@ant-design/icons";

const HomeCreatePost = () => {
    const commonState = useSelector((state) => state.common)
    const [open, setOpen] = useState(false)
    return (
        <div className={"p-2 bg-white shadow-sm rounded"}>
            <div onClick={() => setOpen(true)}
                 className={"d-flex align-items-center justify-content-between cursor-pointer"}>
                <div className={"d-flex align-items-center"}>
                    <div>
                        <img className={"rounded-circle"} style={{width: "2rem"}} alt={""} src={commonState?.currentUser?.avatar}/>
                    </div>
                    <div className={"text-muted mx-2"}>"Nhập nội dung ...."</div>
                </div>
                <div>
                    <Button disabled={true} className={"btn-purple-bright text-white"}>Đăng</Button>
                </div>
            </div>
            <CreatePostModal open={open} setOpen={setOpen}/>
        </div>
    )
}
export default HomeCreatePost

const CreatePostModal = ({open, setOpen}) => {
    return (
        <Modal
            title="Đăng bài mới"
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            cancelText={"Huỷ"}
            okText={"Đăng"}
            width={1000}
        >
            <div className={"my-2"}>Hiển thị với:
                <Select className={" ms-2 w-25"}
                        defaultValue={"public"}
                        options={[
                            {label: "Chi nhánh", value: "public"},
                            {label: "Lớp học", value: "classroom"},
                            {label: "Người cụ thể", value: "users"},
                        ]}
                />
            </div>
            <TextArea size={"large"} placeholder={"Nhập nội dung"}/>

            <div className={"my-2"}>
                <div className={"fw-bold my-2"}>Đính kèm media</div>
                <GalleryUploadFieldComponent/>
                <div className={"fw-bold my-2"}>Đính kèm tài liệu</div>
                <Upload>
                    <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                </Upload>
            </div>

        </Modal>
    )
}