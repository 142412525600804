import WebSocketClient from "./_classes/WebsocketClient";
import {useEffect, useMemo} from "react";
import Notification from './_classes/Notification';
import MainRoute from "./_route/MainRoute";
import {Provider} from "react-redux";
import store from "./_provider/store";
import "react-datetime/css/react-datetime.css";
import LoadingOverlay from "./_layouts/LoadingOverlay";
import {SocketProvider} from "./_provider/SocketContext";


function App() {
    return (
        <Provider store={store}>
            <SocketProvider>
                <div className="App">
                    <LoadingOverlay/>
                    <MainRoute/>
                    {Notification.renderToastContainer()}
                </div>
            </SocketProvider>
        </Provider>
    );
}

export default App;
